@include breakpoint($mobile-breakpoint) {
  [data-mobile-font-size="alpha"] {
    font-size: 3.75em; /* 60px */
    line-height: (72 / 60) + em; 
  }

  [data-mobile-font-size="beta"] {
    font-size: 3.125em; /* 50px */
    line-height: (60 / 50) + em; 
  }

  [data-mobile-font-size="gamma"] {
    font-size: 2.5em; /* 40px */
    line-height: (48 / 40) + em;
  }

  [data-mobile-font-size="delta"] {
    font-size: 1.875em; /* 30px */
    line-height: (36 / 30) + em; 
  }

  [data-mobile-font-size="epsilon"] {
    font-size: 24px; /* 24px */
    line-height: (30 / 24) + em; 
  }

  [data-mobile-font-size="zeta"] {
    font-size: 1.188em; /* 19px */
    line-height: (24 / 19) + em; 
  }

  [data-mobile-font-size="eta"] {
    font-size: 0.938em; /* 16px */
    line-height: (24 / 16) + em; 
  }

  [data-mobile-font-size="theta"] {
    font-size: 0.875em; /* 14px */
    line-height: (24 / 14) + em; 
  }

  [data-mobile-font-size="iota"] {
    font-size: 0.75em; /* 12px */
    line-height: (24 / 12) + em; 
  }
}

@include breakpoint($tablet-breakpoint) {
  [data-tablet-font-size="alpha"] {
    font-size: 3.75em; /* 60px */
    line-height: (72 / 60) + em; 
  }

  [data-tablet-font-size="beta"] {
    font-size: 3.125em; /* 50px */
    line-height: (60 / 50) + em; 
  }

  [data-tablet-font-size="gamma"] {
    font-size: 2.5em; /* 40px */
    line-height: (48 / 40) + em;
  }

  [data-tablet-font-size="delta"] {
    font-size: 1.875em; /* 30px */
    line-height: (36 / 30) + em; 
  }

  [data-tablet-font-size="epsilon"] {
    font-size: 24px; /* 24px */
    line-height: (30 / 24) + em; 
  }

  [data-tablet-font-size="zeta"] {
    font-size: 1.188em; /* 19px */
    line-height: (24 / 19) + em; 
  }

  [data-tablet-font-size="eta"] {
    font-size: 0.938em; /* 16px */
    line-height: (24 / 16) + em; 
  }

  [data-tablet-font-size="theta"] {
    font-size: 0.875em; /* 14px */
    line-height: (24 / 14) + em; 
  }

  [data-tablet-font-size="iota"] {
    font-size: 0.75em; /* 12px */
    line-height: (24 / 12) + em; 
  }
}