// Blues
$denim: #146ec0;
$mariner: #2f79bf;
$onahau: #cce6ff;
$shark: #1c1d26;
$torea-bay: #0e428c;

// Greens
$amazon: #387d5f;
$aqua-forest: #549478;
$frosted-mint: #ddfff0;
$ocean-green: #48a07a;
$salem: #076d41;
$sea-green: #30a06f;
$silver-tree: #62b993;
$vista-blue: #85c9ab;

// Greys
$abbey: #4a4e56;
$athens-grey-1: #f1f2f4;
$athens-grey-1-t-07: rgba(241, 242, 244, 0.7);
$athens-grey-2: #e3e5e9;
$athens-grey-2-t-09: rgba(227, 229, 233, 0.9);
$black: #000000;
$black-t-07: rgba(0,0,0,0.7);
$grey: #808080;
$manatee: #959aa4;
$mischka: #cfd2d9;
$moon-mist: #e1ded1;
$pampas: #f5f4f0;
$shuttle-grey: #626771;
$tuna: #32353b;

// Oranges
$flame-pea: #e05e2a;
$orange-roughy: #e04f14;
$tabasco: #a03910;
$wafer: #e0cec7;

// Reds
$froly: #f27475;
$mona-lisa: #ff9898;

// Yellows
$double-colonial-white: #f0dea6;
$driftwood: #9e8845;
$goldenrod: #f8d56b;
$husk: #b19a44;
$khaki: #efe28b;
$pesto: #7a6a2f;
$ronchi-1: #eed24f;
$ronchi-2: #f0db4f;
$tahuna-sands: #efeac6;

// Other
$white: #fff;